
export enum homeView {
  DashBoard = "DashBoard",
  Content = "Content",
  Category = "Category",
  Feedback = "Feedback",
  Member = "Member",
  Notice = "Notice",
  ElevenLabs = "ElevenLabs",
  PromotionCode = "PromotionCode",
  Coupon = "Coupon",
  Publisher = "Publisher",
  Admin = "Admin"
}

export enum language {
  ko = "ko",
  en = "en",
}

export enum feedback_type {
  ERROR = "10",
  ADD_FUNCTION = "20",
  USE = "30",
  AFFILIATE = "40",
  ETC = "50",
}

export enum feedback_status {
  WAITING = "10",
  COMPLETE = "20"
}


export enum login_type {
  JOIN = "JOIN",
  LOGIN = "LOGIN",
  LOCK = "LOCK",
  ERROR = "ERROR",
}

export enum subscribe_type {
  MONTHLY = "inna_premium_monthly",
  YEARLY = "inna_premium_yearly",
}

export enum iap_source {
  ANDROID = "google_play",
  IOS = "app_store",
}

export enum chart_period {
  DAILY = 'daily',
  MONTHLY = 'monthly',
}

export enum lang_codes {
  EN = 'en-US',
  KO = 'ko-KR',
  JA = 'ja-JP',
  ES = 'es-ES',
  FR = 'fr-FR',
}

export enum sys_lang_codes {
  EN = 'en_US',
  KO = 'ko_KR',
  JA = 'ja_JP',
  ES = 'es_ES',
  FR = 'fr_FR',
}

export enum sample_type {
  SAMPLE_1 = 'sample1',
  SAMPLE_2 = 'sample2',
}

export enum coupon_type {
  MONTHLY = 100,
  YEARLY = 101,
}


const SUBSCRIBE = "subscribe";
const MEMBER = "member";
const CUSTOM_VOICE = "customVoice";
const GIFT_CUSTOM_VOICE = "giftCustomVoice";
const PLAY_ALARM = "playAlarm";
const WITHDRAW = "withDraw";
export const statisticsKey = {
  SUBSCRIBE, MEMBER, CUSTOM_VOICE, GIFT_CUSTOM_VOICE, PLAY_ALARM, WITHDRAW
}